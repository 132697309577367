import { lazy, ReactElement } from 'react';
import { RoutePath } from '../constants/route';
import { Role } from '../constants/role';

const SignIn = lazy(() => import('../pages/signIn/SignIn'));
const Projects = lazy(() => import('../pages/projects/Projects'));
const Layout = lazy(() => import('../layout/Layout'));
const User = lazy(() => import('../pages/user/User'));
const NotFound = lazy(() => import('../pages/404/NotFound'));
const UserAddition = lazy(() => import('../pages/user-management/UserAddition'));
const UserEdition = lazy(() => import('../pages/user-management/UserEdition'));
const Project = lazy(() => import('../pages/project/Project'));
const ProjectDashboard = lazy(() => import('../pages/project/ProjectDashboard'));
const ProjectDetails = lazy(() => import('../pages/project/ProjectDetails'));
const ProjectSettings = lazy(() => import('../pages/project/ProjectSettings'));
const ProjectSyncs = lazy(() => import('../pages/project/ProjectSyncs'));
const HocRoute = lazy(() => import('./HocRoute'));

export interface IRouterMap {
  path: string;
  element: ReactElement;
  roles?: Role[];
  children?: IRouterMap[];
}

export const routerMap: IRouterMap[] = [
  {
    path: RoutePath.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: RoutePath.ROOT_PATH,
    element: (
      <HocRoute>
        <Layout />
      </HocRoute>
    ),
    children: [
      {
        path: RoutePath.PROJECTS,
        element: <Projects />,
        roles: [Role.Admin, Role.Auditor],
      },
      {
        path: RoutePath.PROJECT,
        element: <Project />,
        children: [
          {
            path: RoutePath.PROJECT_DASHBOARD,
            element: <ProjectDashboard />,
            roles: [Role.Admin, Role.Auditor],
          },
          {
            path: RoutePath.PROJECT_DETAILS,
            element: <ProjectDetails />,
            roles: [Role.Admin, Role.Auditor],
          },
          {
            path: RoutePath.PROJECT_SETTINGS,
            element: <ProjectSettings />,
            roles: [Role.Admin],
          },
          {
            path: RoutePath.PROJECT_SYNCS,
            element: <ProjectSyncs />,
            roles: [Role.Admin],
          },
        ],
      },
      {
        path: RoutePath.USER,
        element: <User />,
        roles: [Role.Admin],
      },
      {
        path: RoutePath.USER_ADDITION,
        element: <UserAddition />,
        roles: [Role.Admin],
      },
      {
        path: RoutePath.USER_EDITION,
        element: <UserEdition />,
        roles: [Role.Admin],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
