import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { IRouterMap, routerMap } from './routerMap';
import { useRoutes } from 'react-router-dom';
import { userRolesState } from '../recoil/auth';
import { filterRouterByRoles } from './utils/route';
import { Role } from '../constants/role';

const Router = () => {
  const roles = useRecoilValue(userRolesState);
  const [filteredRouterMap, setFilteredRouterMap] = useState<IRouterMap[]>([]);

  useEffect(() => {
    const allowedRoles: Role[] = Array.from(new Set(roles));
    const newFilteredRouterMap = filterRouterByRoles(routerMap, allowedRoles);
    setFilteredRouterMap(newFilteredRouterMap);
  }, [roles]);

  const routerTab = useRoutes(filteredRouterMap);

  return <>{routerTab}</>;
};

export default Router;
