import { atom } from 'recoil';
import { Role } from '../constants/role';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
export const userRolesState = atom<Role[]>({
  key: 'userRolesState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const userIdState = atom<number | null>({
  key: 'userIdState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
