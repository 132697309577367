import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import theme from './assets/style/theme';
import Router from './router/Router';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

function App() {
  return (
    <ConfirmProvider>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <Router />
          </LocalizationProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </ConfirmProvider>
  );
}

export default App;
