import { IRouterMap } from '../routerMap';
import { Role } from '../../constants/role';

export const filterRouterByRoles = (routes: IRouterMap[], allowedRoles: Role[]): IRouterMap[] => {
  return routes
    .map((route) => {
      if (route.children) {
        const filteredChildren = filterRouterByRoles(route.children, allowedRoles);
        return { ...route, children: filteredChildren };
      }
      return { ...route };
    })
    .filter((route) => !route.roles || route.roles.some((role) => allowedRoles.includes(role)));
};
