import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#012DC8',
    },
    secondary: {
      main: '#e3eef9',
      light: '#cccccc',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;

export const center = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
