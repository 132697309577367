import { Role } from './role';

export const RoutePath = {
  ROOT_PATH: '/',

  SIGN_IN: '/sign-in',
  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  PROJECT_DASHBOARD: '/projects/:id/dashboard',
  PROJECT_DETAILS: '/projects/:id/details',
  PROJECT_SETTINGS: '/projects/:id/settings',
  PROJECT_SYNCS: '/projects/:id/syncs',
  USER: '/users',
  USER_ADDITION: '/user-addition',
  USER_EDITION: `/user-edition/:id`,
};

export interface IMenuItems {
  name: string;
  path: string;
  roles: Role[];
}

export const MenuItems: IMenuItems[] = [
  {
    name: 'Projects',
    path: RoutePath.PROJECTS,
    roles: [Role.Auditor, Role.Admin],
  },
  {
    name: 'Users',
    path: RoutePath.USER,
    roles: [Role.Admin],
  },
];
